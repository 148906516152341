<template>
  <div class="administer">
    <AiPrediction :mark="mark"></AiPrediction>
    <div class="dialogue">
      <AiDialogue v-if="params == 2" :mark="mark"></AiDialogue>
      <div class="article" v-if="params == 0 && mark == 'sd'">
        <p>
          全国农技中心组织各省（区、市）测报技术人员和有关专家会商，结合水稻病虫基数、水稻栽培管理和品种布局、今冬明春气候条件等因素综合分析，预计2022年全国水稻病虫害将呈偏重发生态势，发生面积12.3亿亩次。其中，虫害发生面积8.3亿亩次，病害发生面积4.0亿亩次；白背飞虱和褐飞虱、稻纵卷叶螟、二化螟、水稻纹枯病偏重发生，稻瘟病、稻曲病中等发生，三化螟、水稻病毒病偏轻发生。
        </p>
        <h6>一、发生趋势</h6>
        <h5>（一）虫害</h5>
        <span>
          白背飞虱和褐飞虱在南方稻区总体偏重发生，全国发生面积3.1亿亩次。其中，白背飞虱在西南东部稻区偏重发生，南方其他稻区中等发生，全国发生面积1.6亿亩次；褐飞虱在江南、长江中下游和华南东部稻区偏重发生，南方其他稻区中等发生，全国发生面积1.5亿亩次。
          稻纵卷叶螟在江南、长江下游和华南东部稻区偏重发生，南方其他稻区中等发生，全国发生面积2.2亿亩次。
          二化螟在江南、长江中游和西南北部稻区偏重发生，其他稻区中等发生，全国发生面积2.0亿亩次。
          三化螟在西南北部稻区中等发生，华南、西南的其他稻区偏轻至轻发生，全国发生面积1000万亩次。
          灰飞虱、大螟、稻秆潜蝇、粘虫、台湾稻螟、水稻跗线螨等其他虫害在部分稻区有一定程度发生，全国发生面积9000万亩次。
        </span>
        <h5>（二）病害</h5>
        <span>
          水稻纹枯病在江南、华南、长江中下游、西南北部和东北南部稻区偏重发生，西南南部和东北北部稻区中等发生，全国发生面积2.4亿亩次。
          稻瘟病总体中等发生，东北主产区、南方丘陵山区和沿江沿淮等常发稻区存在偏重以上流行风险，尤其感病品种重发风险高，全国发生面积6000万亩次。
          稻曲病在南方和东北南部稻区总体中等发生，长江中下游沿江沿淮稻区存在偏重发生风险，全国发生面积3600万亩次。
          南方水稻黑条矮缩病在南方稻区总体偏轻发生，华南东部和江南中部局部稻区存在中等发生风险，全国发生面积200万亩次。
          水稻白叶枯病、胡麻叶斑病、根结线虫病等其他病害在部分稻区有一定程度发生，全国发生面积6200万亩次。
        </span>
        <h6>二、预测依据</h6>
        <h5>（一）钻蛀性害虫冬前基数高，常发性病害菌源充足</h5>
        <span>
          各地冬前调查二化螟平均亩残虫量，江西达1.46万头，分别比2020年同期和2011—2020年均值增加1.9倍和2.2倍，其中永修达23.3万头，分别比2020年同期和2011—2020年均值增加9倍和3.2倍；湖南6553头，分别比2020年同期和2011—2020年均值增加20.1%和65.3%，其中18个县（市、区）过万头；西南北部、华南东部、长江中游和江淮稻区为1700—3500头，其中安徽沿江中西部和皖南东部8县达5500头—2.6万头，具备偏重至大发生的虫源基数。水稻纹枯病、稻瘟病、稻曲病等病害在常发区菌源多年积累，具备中等以上发生程度的菌源基础。
        </span>
        <h5>（二）水稻种植制度和栽培管理措施有利于病虫害发生</h5>
        <span>
          华南和江南稻区单、双季稻混栽面积大，南方稻区品种多样、栽培方式各异，导致播栽期长、生育期差异大、桥梁田多，有利于“两迁”害虫、螟虫等害虫辗转为害。水稻品种以粗秆大穗型为主，易形成适温高湿郁闭的田间小气候，有利于病虫害发生。免耕、机收等轻简化农艺措施广泛推行，冬闲田多，残留稻茬高，有利于病虫越冬。偏施、迟施氮肥造成水稻后期贪青，有利于病虫害发生。
        </span>
        <h5>（三）水稻品种抗性总体偏弱，重大病虫抗药性发展</h5>
        <span>
          水稻主产县的139个主栽品种稻瘟病发病风险达较高级别的占59.0%；南方稻区优质稻、超级稻主推品种对稻瘟病、稻曲病抗性不强，增加了病害流行风险。据主产稻区定点监测，褐飞虱对吡虫啉、噻虫嗪、噻嗪酮产生高水平抗性，二化螟对氯虫苯甲酰胺、阿维菌素抗性出现区域性升高，防治难度加大。植保无人机施药大面积推广，对褐飞虱、螟虫、纹枯病等茎基部病虫害防效不彻底，有利于病虫源积累。
        </span>
        <h5>（四）气象条件总体有利于病虫害发生</h5>
        <span>
          据国家气候中心预测，2022年1—2月，我国南方稻区气温接近常年同期到偏高，其中江南东南部和华南东部偏高1—2℃，有利于水稻病虫害越冬。春季，水稻主产区大部气温总体偏高、降水总体偏少，有利于水稻螟虫等钻蛀性害虫的发育繁殖和发生为害，对稻瘟病、纹枯病等病害的流行有一定抑制作用。夏季，全国大部气温接近常年同期或偏高，东北、华南、西南地区西部稻区降水偏多，有利于迁飞性害虫和流行性病害的发生为害；登陆我国的台风个数较常年偏多，强度偏强，盛夏可能有北上台风，将进一步增大“两迁”害虫的迁飞扩散威胁以及白叶枯病等细菌性病害的流行风险。
        </span>
      </div>
      <div
        class="article"
        v-else-if="params == 0 && mark == 'dd'"
        style="color: #fff"
      >
        <p>
          进入8月后大豆进入开花、结荚期，从开花到成熟这一时期病虫害种类多、危害重，是防治病虫害保证产量和品质的关键阶段。病害主要有紫斑病、霜霉病、菌核病、细菌性斑点病等，主要虫害有大豆卷叶螟、大豆造桥虫等；特别注意结荚到鼓粒期，一定要根据病情喷施药剂进行防治。
        </p>
        <h5>一、主要病虫害发生情况及预测</h5>
        <span>
          1、主要虫害
          据8月1日-8月2日大面积调查：田间有大豆卷叶螟、豆叶东潜蝇、甜菜夜蛾、棉铃虫及蜗牛等虫害发生，其中大豆卷叶螟危害株率为0.2%、甜菜夜蛾危害株率为0.2%。在往年的大豆栽培中，大豆食心虫、大豆卷叶螟、豆荚螟、大豆造桥虫等的危害严重，造成减产1-2成；今年要特别注意甜菜夜蛾，据7月1日-8月2日高空灯单灯累计诱蛾量1411头，诱蛾量居近3年首位，甜菜夜蛾为杂食性害虫，是喜温而又耐高温的害虫，近期高温干旱，利于害虫的发生为害。预测甜菜夜蛾中等偏重发生，其它虫害中等发生。
        </span>
        <span>
          2、主要病害
          据8月1日-8月2日大面积调查：田间暂未发现病害发生，但据气象部门预测，8月份降水量较常年偏少，平均气温较常年偏高，高温日数偏多，天气条件不利于大豆病害的发生发展。预测紫斑病、霜霉病、菌核病、细菌性斑点病等病害偏轻至中等发生。
        </span>
        <h5>二、防治技术意见</h5>
        <h6>（一）大豆病害</h6>
        <span>
          1、防治大豆紫斑病、炭疽病、灰斑病等病害，可用：丙森锌+加托、苯甲·嘧菌酯、戊唑·肟菌酯等。
          2、防治大豆菌核病可用：苯醚甲环唑、嘧菌酯、吡唑·嘧菌酯、苯醚·嘧菌酯、烯酰吗啉、噻呋酰胺等复配制剂。
          3、防治细菌性斑点病可用：噻唑锌、碱式硫酸铜、琥胶肥酸铜、春雷霉素·氧氯化铜、松脂酸铜等
          4、防治霜霉病可用：烯酰吗啉、甲霜灵、甲霜灵·代森锰锌等。
        </span>
        <h6>（二）大豆虫害</h6>
        <span>
          1、防治棉铃虫、甜菜夜蛾、斜纹夜蛾等夜蛾类害虫。可以选用氯虫苯甲酰胺、甲维盐、氟铃脲等喷雾;蚜虫、烟粉虱选用抗蚜威、吡虫啉、氟啶虫胺腈、噻虫·高氯氟等药剂喷雾，同时喷施寡糖链蛋白等预防病毒病。大面积大豆田害虫防治，可结合田间安装太阳能杀虫灯，诱杀金龟子、蝼蛄、棉铃虫、斜纹夜蛾、甜菜夜蛾等害虫成虫;田间悬挂黄板诱杀烟粉虱。
          2、防治大豆食心虫，在大豆食心虫成虫始盛期后7-10天，可选用氯氟氰菊酯、氯虫苯甲酰胺、甲维盐、氟铃脲等防治。
          3、防治点蜂缘蝽在大豆植株现蕾、开花和初荚初期，选用噻嗪酮、吡虫啉、氰戊菊酯、氯虫苯甲酰胺等，隔7-10天喷1次，轮换用药，连喷2-3次，同时可兼治其它蝽类害虫。
          4、防治豆荚螟可在豆荚螟产卵高峰后5-7天，可选用氯虫苯甲酰胺、氰戊菊酯等防治，同时兼治大豆造桥虫。
        </span>
        <h6>（三）注意事项</h6>
        <span>
          针对不同防治对象，选用高效、对路农药，科学规范施用，提高防治效果。防治时要用足水量，同时要注意不同作用机制药剂的交替轮换使用。施药时要穿好防护服，戴好手套和口罩；同时，避免在高温期间作业，防止中毒中暑事故发生。施药完毕后，做好药剂包装物的回收，避免随意丢弃田间地头，造成环境污染。
        </span>
      </div>
      <div
        class="article"
        v-else-if="params == 0 && mark == 'dp'"
        style="color: #fff"
      >
        <p>
          大棚蔬菜是现代农业中的一种重要种植方式，但是由于环境封闭、灌溉、施肥等因素的影响，大棚蔬菜容易受到各种病虫害的侵袭。为了提高大棚蔬菜的产量和质量，必须及时预防和控制病虫害。
        </p>
        <h5>一、大棚蔬菜病虫害发生特点：</h5>
        <h6>1.病虫害种类多</h6>
        <span>
          设施保护地内高湿弱光、温差大，利于病虫繁殖。病虫害生活周期缩短，世代增多，发生数量加大，易发，多发唤正，重发。棚室中通风不畅，有害气体浓度高、CO2
          浓度低、连作加重*壤盐渍化趋势明显，中毒、沤根、营养失衡等生理性病害常发，影响单株及群体长势，抗逆、抗病虫性下降。另外，栽培品种单一，不注意轮作倒茬，*传病原菌和害虫在*壤中大量积累，容易造成流行。
        </span>
        <h6>2.发生规律复杂</h6>
        <span>
          无论是病害还是虫害，在棚室中发生规律均比露地条件更为复杂。主要表现在：①越冬期缩短或无越冬期。冬、春季棚室内温度远高于棚室外面，加之蔬菜育苗和生产处于连续状态，寄主连年存在，使许多本应在冬季休眠越冬的病虫，其越冬期大为缩短，有的甚至无越冬期，终年发生。②初侵染源数量大。正是由于棚室内蔬菜病虫越冬期缩短或无越冬期，其初次侵染基数相对较露地大得多。③病菌再侵染次数和害虫发生代数增多。
        </span>
        <h6>3.传播蔓延快，危害严重</h6>
        <span>
          无论是病害还是虫害，在棚室中发生规律均比露地条件更为复杂。主要病原菌的孢子形成、萌发、侵染均需要相对充足的温、湿度条件。大棚内由于空气流通性小，湿度高，光照差，温度适宜，容易使病原菌侵入作物体内，并进行快速繁殖和传播危害，传播速度快、数量多，危害期长，危害重。
        </span>
        <h5>二：病害</h5>
        <h6>霜霉病：在湿度高、气温低的环境下易发生，应及时通风、减少湿度。</h6>
        <h6>灰霉病：在高温多湿的环境下易发生，应保持通风、控制湿度。</h6>
        <h6>立枯病：在高温干旱的环境下易发生，应增加灌溉量。</h6>
        <h5>三：虫害</h5>
        <h6>蚜虫：在植株生长旺盛期易发生，应及时喷洒杀虫剂。</h6>
        <h6>粉虱：在高温干燥的环境下易发生，应增加湿度。</h6>
        <h6>斑潜蝇：在植株生长旺盛期易发生，应及时喷洒杀虫剂。</h6>
      </div>
      <div class="article" v-else-if="params == 0 && mark == 'xm'">
        <p>
          据全国农技中心组织全国植保体系和科研教学单位专家会商分析，预计2023年全国小麦主要病虫害总体偏重发生，发生面积8.6亿亩次，其中病害发生4.6亿亩次，虫害发生4.0亿亩次。蚜虫在黄淮海大部麦区偏重至大发生，赤霉病在长江中下游、江淮和黄淮南部麦区偏重流行风险高，纹枯病在华北麦区偏重发生，条锈病在汉水流域和西北麦区局部偏重发生。
        </p>
        <h5>一、发生趋势</h5>
        <h6>（一）病害</h6>
        <span>
          赤霉病总体偏重流行，湖北大部、浙江北部、安徽大部、江苏中南部、河南南部麦区偏重以上流行风险高；江苏北部、河南中北部、山东南部、陕西关中、四川北部和东部中等至偏重流行；预计发生面积9000万亩，需预防控制面积在3亿亩次左右。
          条锈病总体中度流行，湖北大部、河南南部和甘肃南部偏重流行风险高，河南中北部、安徽中西部、山东西南部、四川盆地、陕西大部、甘肃中东部、宁夏南部和新疆伊犁河谷等麦区中等发生；预计发生面积4000万亩。
          白粉病总体中等发生，江苏中北部、山东西部、河南东部和北部偏重发生，黄淮和华北和西北的其他大部麦区中等发生；预计发生面积9000万亩。
          纹枯病总体偏重发生，河南、山东和安徽大部、江苏中北部、河北南部偏重发生，江淮、黄淮、华北的其他麦区中等发生；预计发生面积1.2亿亩。
          其他病害预计发生面积为1.2亿亩，为偏轻至中等发生。其中，茎基腐病在黄淮和华北大部麦区呈加重发生态势，预计发生5000万亩；叶锈病在华北、黄淮、江淮、西南、西北麦区发生4000万亩；根腐病、全蚀病、叶枯病等病害在黄淮和华北的部分麦区会造成一定危害，预计发生面积3000万亩。
        </span>
        <h6>（二）虫害</h6>
        <span>
          蚜虫总体偏重发生，黄淮海麦区偏重至大发生，江淮、西南、西北大部麦区中等发生；预计发生面积2.2亿亩次。
          麦蜘蛛总体中等发生，主要发生在江淮、黄淮和华北地区的中西部、西北地区中东部麦区；预计发生面积9000万亩。
          其他虫害预计发生面积8500万亩，总体为偏轻发生。其中，地下害虫在西北和华北麦区发生4000万亩；一代粘虫在长江中下游、江淮、黄淮南部麦区发生1000万亩，麦叶蜂在华北麦区发生面积1500万亩；吸浆虫、土蝗等害虫在部分麦区预计发生面积2000万亩。
        </span>
        <h5>二、预测依据</h5>
        <h6>
          （一）赤霉病、白粉病、蚜虫、麦蜘蛛冬前基数偏高，条锈病、纹枯病和吸浆虫基数偏低
        </h6>
        <span>
          赤霉病菌源逐年积累、数量丰富。近几年长江中下游、江淮和黄淮等麦区连续重发，且发生范围东移北扩明显。大部麦区小麦与玉米和水稻轮作，田间秸秆存量大，为赤霉病菌繁殖和积累提供了重要载体，主产麦区田间菌源量满足大流行的基数条件。
          条锈病秋苗西北主发区发生面积较小，总体病情重于2021年、轻于常年。截至2022年12月5日，西北主发区的甘肃、宁夏、青海、陕西4省（区）12市（州）37个县见病，发生面积85.9万亩，为2010年以来第三小的年份。发生面积比轻发生的2021年同期增加23.1%，比2016—2020年均值减少66.4%；发生县数比2021年同期增加11个，比2016—2020年均值减少14个。其中，甘肃定西、宁夏固原发生较重，平均病田率均超过30%。西南冬繁区四川、贵州、云南3市5个县见零星发病田块，见病时间较2021年同期早9—18天。
          白粉病秋苗基数较高。西北、黄淮、西南等部分麦区秋苗见病，发生面积289.3万亩，比2021年同期增加55.5%，比2016—2020年同期均值减少30.8%，平均病叶率4.9%，远高于2021年同期的0.05%和2016—2020年同期均值0.54%。甘肃东部和南部早播和旺长田发生较重，平均病叶率6.6%，最高60%。山西平均病叶率为2.6%，较2021年和常年分别增加1.6倍和2.8%。陕西平均病叶率为0.18%，低于2021年的0.2%，但潼关、印台平均病叶率达3%—4.8%。河南北部零星见病，病情接近常年，四川零星见病，病情接近2021年同期，轻于常年。
          纹枯病秋苗发病面积较小，病情轻于2021年和常年。黄淮、华北等麦区发生面积655.4万亩，比2021年同期增加6.9%，比2018—2020年同期均值减少5.7%。全国平均病株率1.4%，低于2021年同期的2.8%和2018—2020年同期均值的1.9%。其中，山东、河南平均病株率分别为1.8%和1.4%，河北、山西平均病株率分别为1.0%和0.8%，均低于2021年和常年，河北永年最高达6.5%。
          蚜虫冬前大部麦区虫量偏高。全国秋苗发生面积951.9万亩，比2021年同期增加36.2%，比2016—2020年同期均值减少37.8%。各地平均百株蚜量，江苏、甘肃、湖北、山西、陕西、河北1.2—8.0头，均高于2021年同期；安徽15.6头，山东2.1头，分别低于2021年同期的22.7头和8.0头。
          麦蜘蛛冬前发生面积偏大，虫量偏高。黄淮、江淮、华北、西北等麦区发生1218.5万亩，比2021年同期增加49.5%，比2018—2020年同期均值增加2.1%。全国平均每尺单行螨量为12.9头，高于2021年同期的6.3头和2018—2020年同期均值的7.3头。其中，河南、陕西、山西、安徽、湖北平均每尺单行螨量10.9—32头，高于2021年同期。
          吸浆虫在黄淮海和西北常发区虫口密度偏低。全国平均每样方虫量0.54头，低于2021年同期的0.9头和2018—2020年同期均值的1.2头。各地平均每样方虫量，河北、陕西分别为0.2头和0.5头，低于2021年同期；河南西峡和陕西扶风最高分别为8头和9头，但高密度田块和虫量均明显低于2021年同期。
        </span>
        <h6>（二）品种抗性和种植制度有利于病虫害发生</h6>
        <span>
          全国大部麦区冬小麦品种对小麦赤霉病、条锈病、白粉病、纹枯病、茎基腐病等病害抗性水平低。据调度，陕西、河南、安徽、山东大部种植小麦品种仍主要为赤霉病感病品种；甘肃、陕西等小麦条锈病菌源区种植抗病品种比例有所上升，但致病力强、毒性谱宽的条中34号小种仍是优势小种，湖北、河南、山东等主产麦区种植品种条锈病抗性一般；主产麦区种植品种对白粉病和纹枯病抗性也较低。2022年秋季，全国大部冬麦区气温偏高、降水偏多，多地出现小麦旺长，对抗病虫不利。
        </span>
        <h6>（三）气象条件有利于蚜虫和赤霉病发生流行</h6>
        <span>
          据国家气候中心预测，2022年冬季（2022年12月至2023年2月），全国大部地区偏暖，降水偏少，对病虫越冬没有明显不利因素。2023年春季（3—5月），全国大部地区气温较常年偏高，西南东北部、西北大部等地降水较常年同期偏多，有利于条锈病、白粉病的发生流行，华北大部降水偏少，有利于麦蚜、麦蜘蛛数量快速上升；长江中下游、江淮和黄淮大部降水正常，对病虫发生总体有利。长江中下游、江淮和黄淮南部小麦抽穗扬花期易遇连阴雨天气，利于赤霉病发生流行。
        </span>
      </div>
    </div>
    <AiSymptom :mark="mark"></AiSymptom>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    AiSymptom: () => import("./components/aiSymptom.vue"),
    AiPrediction: () => import("./components/aiPrediction.vue"),
    AiDialogue: () => import("./components/aiDialogue.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },

  data() {
    return {
      // 0 是预报时间； 2 是 对话
      params: "",
      // 对应的作物
      mark: "",
    };
  },
  created() {
    this.params = this.$route.params.type;
    this.mark = this.$route.params.mark;
  },
};
</script>

<style lang="less" scoped>
.administer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 62px 0 23px;
  background-color: #071a2c;

  .dialogue {
    flex: 1;
  }
  .article {
    width: 100%;
    height: 100%;
    padding: 76px 56px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #3e90e5;
    }
    &::-webkit-scrollbar-track {
      background-color: #3e90e5;
    }
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      line-height: 30px;
      margin-bottom: 24px;
      text-indent: 2em;
    }
    h5 {
      font-family: Microsoft YaHei;
      color: #fff;
      margin-bottom: 24px;
    }
    h6 {
      font-family: Microsoft YaHei;
      color: #fff;
      margin-bottom: 24px;
    }
    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a9b8c8;
      line-height: 30px;
      margin-bottom: 24px;
      display: inline-block;
      text-indent: 2em;
    }
  }
}
</style>
